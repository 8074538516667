<script setup>
import UnavailableTimeSlot from "./UnavailableTimeSlot.vue"

const emit = defineEmits(["select-slot"])

const props = defineProps({
  available: {
    type: Boolean,
    required: true,
  },
  notAvailableReason: {
    type: String,
    required: false,
  },
  time: {
    type: String,
    required: true,
  },
  selectedSlot: {
    type: Object,
    required: true,
  },
  date: {
    type: String,
    required: true,
  },
  fromPrice: {
    type: Number,
    required: true,
  },
})

const selectSlot = (date, time) => {
  emit("select-slot", { date, time })
}
</script>

<template>
  <div
    v-if="available"
    class="md:w-[125px] text-center py-2 md:py-7 px-3 md:px-5 mx-1 md:mx-2 rounded-lg border hover:border-f-tertiary hover:hj:border-white cursor-pointer drop-shadow-md h-full flex flex-col justify-center"
    :class="{
      'rounded-lg !bg-f-tertiary border-f-tertiary hj:border-white hj:!bg-f-contrast font-bold bo:text-f-white':
        time === selectedSlot.time && date === selectedSlot.date,
      'border-transparent hj:border-f-tertiary bg-f-tertiary/[.07] hj:bg-f-tertiary':
        time !== selectedSlot.time || date !== selectedSlot.date,
    }"
    @click="selectSlot(date, time)">
    {{ time }}<br />
    <span
      :class="{
        '!text-f-white hj:text-f-tertiary': time === selectedSlot.time && date === selectedSlot.date,
      }"
      class="text-f-contrast bo:text-f-tertiary font-bold whitespace-nowrap">
      from £{{ fromPrice }}
    </span>
  </div>
  <UnavailableTimeSlot v-if="!available" :reason="notAvailableReason" :time="time" />
</template>
